<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row>
        <v-col>
          <h2>協会費請求 メール送信画面</h2>
        </v-col>
      </v-row>

      <v-row>
      <v-col cols="6">
      <v-row>
        <v-col cols="9">
          <v-text-field
            label="発信者"
            v-model="mailData.mailFrom"
            dense
            :readonly="true"
            :rules="[required]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="text-center">
          <v-btn class="btn_font primary" 
                width="120" as
                v-on:click="showEmailAddress(0, true, mailDestArray.tmpFromList)"
                :disabled="loading" 
          >
            <v-icon :small="true">mdi-border-color</v-icon>発信者変更
          </v-btn>
        </v-col>
      </v-row>
      </v-col>
      <!-- </v-row>
      <v-row> -->
      <v-col cols="6">
      <v-row>
        <v-col cols="9">
          <v-text-field
            label="宛先"
            v-model="mailData.mailTo"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="text-center">
          <v-btn class="btn_font primary" 
                width="120" 
                v-on:click="showEmailAddress(1, false, mailDestArray.tmpToList)"
                :disabled="loading"
          >
            <v-icon :small="true">mdi-border-color</v-icon>宛先変更
          </v-btn>
        </v-col>
      </v-row>
      </v-col>
      </v-row>

      <v-row>
      <v-col cols="6">
      <v-row>
        <v-col cols="9">
          <v-text-field
            label="CC"
            v-model="mailData.mailCC"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="text-center">
          <v-btn class="btn_font primary" 
                width="120" 
                v-on:click="showEmailAddress(2, false, mailDestArray.tmpCCList)"
                :disabled="loading"
          >
            <v-icon :small="true">mdi-border-color</v-icon>CC変更
          </v-btn>
        </v-col>
      </v-row>
      </v-col>
      <!-- </v-row>
      <v-row> -->
      <v-col cols="6">
      <v-row>
        <v-col cols="9">
          <v-text-field
            label="BCC"
            v-model="mailData.mailBCC"
            dense
            :readonly="true"
            :clearable="false"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="text-center">
          <v-btn class="btn_font primary" 
                width="120" 
                v-on:click="showEmailAddress(3, false, mailDestArray.tmpBCCList)"
                :disabled="loading"
          >
            <v-icon :small="true">mdi-border-color</v-icon>BCC変更
          </v-btn>
        </v-col>
      </v-row>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="件名"
            v-model="mailData.subject"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[required]"
            :maxLength="100"
            counter="100"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            label="本文"
            v-model="mailData.body"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[required]"
            :maxLength="30000"
            :loading="loading"
            counter="30000"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            label="署名"
            v-model="mailData.signature"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[]"
            :maxLength="30000"
            :loading="loading"
            counter="30000"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-for="n in (attachment.length + 1)" :key="n">
        <v-col cols="12">
          <!-- <div
            @dragenter="dragEnter"
            @dragleave="dragLeave"
            @dragover.prevent
            @drop.prevent="dropFile"
          > -->
          <div>
            <v-file-input
              :background-color="isEnter ? 'info' : 'transparent'"
              label="添付"
              v-model="attachment[n-1]"
              dense
              :readonly="false"
              :clearable="true"
              :rules="[]"
              :loading="loading"
              truncate-length="50"
              placeholder="ファイルを選択します。"
              persistent-placeholder
              @change="filterTmpAttachment"
            ></v-file-input>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table 
                :headers="headers" 
                :items="associationList" 
                :disable-pagination='true'
                :hide-default-footer='true'
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-checkbox v-model="item.enable" ></v-checkbox>
                </td>
                <td>{{ item.association_id }}</td>
                <td>{{ item.association_name }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.file_name }}</td>
                <!-- <td>{{ item.download_path }}</td> -->
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- modal -->
      <modal name="modal-dialog-from" :draggable="false" :clickToClose="false" :scrollable="true" 
                                  height="auto" width="80%" id="vm--modal-from">
        <div class="">
          <div class="d-flex modal-header mt-5 justify-space-between">
            <h2>メールアドレス選択</h2>
            <v-icon x-large v-on:click="hideFrom()" :disabled="loading">mdi-close-circle</v-icon>
          </div>
          <div class="modal-body">
            <SelectEmailAddress v-on:closeEvt="hideFrom" v-on:registEvt="updateEmailAddressList" :selectedList="modalSelectedList" :type="emailType" :solo="emailSolo" />
          </div>
        </div>
      </modal>

      <!-- アクション -->
      <v-row>
        <v-col cols="6" class="text-center">
          <v-btn class="" color="primary" width="160" :disabled="loading || (enableList.length <= 0)" @click="sendRequest()"><v-icon :small="true">mdi-send</v-icon>メール送信</v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn class="btn_font primary" width="160" v-on:click="cancel()" :disabled="loading">キャンセル</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import SelectEmailAddress from "./SelectEmailAddress.vue";
import MailTemplate from "../model/mailTemplate";
import MailSend from "../model/mailSend";
import User from "../model/m_user";

import Message from "../Lib/Message";
import Debug from "../Lib/Debug";

export default {
  components: {
    SelectEmailAddress,
  },

  data: () => ({
    valid: true,
    loading: false,

    // ファイル ドラッグアンドドロップ
    isEnter: false,

    // type(日冷倉協or冷事協会)
    type: "",

    // メールアドレス選択
    emailType: 0,
    emailSolo: false,

    // テーブルヘッダー
    headers: [
      { text: "送信", align: "left", sortable: true, value: "enable", width: "30" },
      { text: "協会ID", align: "left", sortable: true, value: "association_id", width: "60" },
      { text: "協会名", align: "left", sortable: true, value: "association_name", width: "160" },
      { text: "宛先", align: "left", sortable: true, value: "name", width: "120" },
      { text: "メールアドレス", align: "left", sortable: true, value: "email", width: "160" },
      { text: "請求書ファイル名", align: "left", sortable: true, value: "file_name", width: "160" },
      // { text: "ファイルパス", align: "left", sortable: true, value: "download_path", width: "200" },
    ],

    // データ
    associationList: [],

    // テンプレートデータ
    templateData: {
      templateId: null,
      type: 0,
      mailFrom: '',
      mailTo: '',
      mailCC: '',
      mailBCC: '',
      subject: '',
      body: '',
      signature: '',
    },

    // メールデータ(タイトル・本文・署名)
    mailData: {},
    // メールアドレス一時リスト{email,memberNo,toAddress}
    mailDestArray: {
      tmpFromList: [],
      tmpToList: [],
      tmpCCList: [],
      tmpBCCList: [],
    },

    // 添付一時ファイル(ドラッグアンドドロップ用)
    tmpDropFiles: [],

    pdfData: null,

    // メールデータ
    sendData: {
      // 送信対象(協会一覧)
      dest: [],
      formatType: 2,    // 協会費請求
    },

    // 添付ファイル
    attachment: [],

    // モーダル用リスト
    modalList: [],
    modalSelectedList: [],
  }),

  // ライフサイクルフック
  created: function () {

  },

  mounted: async function () {

    this.associationList =  JSON.parse(this.$route.query.associationList);
    this.type = this.$route.query.type;

    Debug.log2("AssociationList", this.associationList);
    Debug.log2("type:", this.type);

    // メールのテンプレートを取得
    await this.getMailTempleteData();

    // 送信するメールアドレスを取得（協会IDの配列を送信する）
    await this.getSendAddressList(this.associationList.map(el => el.association_id), this.type);

    Debug.log2("AssociationList", this.associationList);

},

  methods: {
    
    // 送信メールのテンプレートを取得
    async getMailTempleteData() {
      Debug.log('function[getData]');
      this.loading = true;

      const tmplType = this.type == 'nichirei' ? 6 : 7;
      Debug.log2("tmplType:", tmplType);

      try {
        // お知らせのメールテンプレート取得
        const res = await MailTemplate.getMailTemplateDataForType(tmplType);
        Debug.log(res.data);
        // レスポンス確認
        if(res.data.template.length > 0 && res.status == 200) {
          this.templateData = res.data.template[0];
          this.mailDestArray.tmpFromList = res.data.from;
          this.mailDestArray.tmpToList = res.data.to;
          this.mailDestArray.tmpCCList = res.data.cc;
          this.mailDestArray.tmpBCCList = res.data.bcc;
        }
        this.mailData = JSON.parse(JSON.stringify(this.templateData));

        Debug.log2('mailData:', this.mailData);
        Debug.log2('mailDestArray:', this.mailDestArray);

      } catch (error) {
        Message.err(error, "メールテンプレートを取得できませんでした");
      }
      this.loading = false;
    },

    // 送信先協会アドレスを取得
    getSendAddressList(idList, type) {
      Debug.log("function[getSendAddressList]");
      Debug.log2("  idList", idList);

      User.getAssociationFeeUserList(idList, type)
            .then(res => {
              const maillist = res.data;

              // 送信データ一覧を作成
              Debug.log2("SendMailList", maillist);

              let output = [];
              idList.forEach(id => {
                const pdfData = this.associationList.find(al => al.association_id == id);
                const ascMailList = maillist.filter(li => li.association_id == id);
                ascMailList.forEach(ml => {
                  output.push({association_id: ml.association_id, 
                                association_name: ml.association_name,
                                name: ml.name,
                                email: ml.email,
                                download_path: pdfData.Download_path,
                                file_name: pdfData.File_name,
                                enable: true,
                              });
                });              
              });

              Debug.log(output);
              // this.associationList を置き換える
              this.associationList = output;
            })
            .catch((err) => {
              Message.err(err, "メール情報が取得できませんでした");
            })
    },

    // メールアドレス更新
    updateEmailAddressList (type, innerSelectedList, tmpStr) {
      Debug.log('function[updateEvt]');
      this.loading = true;

      // 編集画面での変更を反映する
      switch(type) {
        case 0:
          // 発信者
          this.mailData.mailFrom = tmpStr;
          this.mailDestArray.tmpFromList = [innerSelectedList];
          Debug.log(this.mailDestArray.tmpFromList);
          break;

        case 1:
          // 宛先
          this.mailData.mailTo = tmpStr;
          this.mailDestArray.tmpToList = innerSelectedList;
          break;

        case 2:
          // CC
          this.mailData.mailCC = tmpStr;
          this.mailDestArray.tmpCCList = innerSelectedList;
          break;

        case 3:
          // BCC
          this.mailData.mailBCC = tmpStr;
          this.mailDestArray.tmpBCCList = innerSelectedList;
          break;
      }

      this.loading = false;
    },

    /** アドレス帳モーダル表示用 */
    showEmailAddress(type, solo, selectedList) {
      Debug.log("function[show]");

      this.emailType = type;
      this.emailSolo = solo;
      this.modalSelectedList = selectedList;
      this.$modal.show("modal-dialog-from");
    },

    hideFrom: function () {
      Debug.log("function[hide]");
      this.$modal.hide("modal-dialog-from");
    },

    /** 取消 */
    cancel() {
      Debug.log("function[cancel]");

      if (!confirm("キャンセルします。よろしいですか？")) return;

      this.$router.back();
    },

    /** 送信 */
    async sendRequest() {
      Debug.log("function[sendRequest]");

      if (!this.$refs.form.validate()) {
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("請求書を送信します。よろしいですか？")) return;
      this.loading = true;
      try {
        let res = null;

        // とりあえずメール送信のみ this.sendDataには協会リストを入れる
        this.sendData.dest = this.associationList;
        res = await MailSend.send(this.mailData, this.mailDestArray, this.sendData, this.attachment);

        // サーバーサイドのバリデーション 
        if(res.status == 200) {
          const validationMsg = res.data;
          if (validationMsg) {
            let message = "";
            if (Array.isArray(validationMsg)) {
              validationMsg.forEach(m => message += m + '\n')
            } else {
              message = validationMsg;
            }
            alert(message);

            this.loading = false;
            return;
          } else {
            // 送信成功
            alert("請求書を送信しました。");
            this.$router.back();
          }
        }

      } catch (error) {
        Message.err(error, "請求書を送信できませんでした");
      }

      this.loading = false;
    },

    // ファイル ドラッグアンドドロップ
    dragEnter() {
      Debug.log('function[dragEnter]');
      this.isEnter = false;
    },
    dragLeave() {
      Debug.log('function[dragLeave]');
      this.isEnter = false;
    },
    dropFile() {
      Debug.log('function[dropFile]');
      // ドロップされたファイルの取得
      this.tmpDropFiles = [...event.dataTransfer.files];
      Debug.log(this.tmpDropFiles);

      // 添付ファイルに追加
      for(let i=0; i < this.tmpDropFiles.length; i++) {
        this.attachment.push(this.tmpDropFiles[i]);
      }
      // 一時ファイルの初期化
      this.tmpDropFiles = [];

      this.isEnter = false;
    },
    filterTmpAttachment() {
      // リストからnullを削除
      Debug.log('function[filterTmpAttachment]');
      this.attachment = this.attachment.filter(v => !!v);
    },
  },

  computed: {
    enableList() {
      return this.associationList.filter(item => item.enable == true);
    },
  }

}
</script>

<style scoped>

button + button {
  margin-left: 20px;
}

.disablecolor {
  background: #bbbbbb;
}

.growClear {
  flex-grow: 0;
}

</style>
